import React from "react"
import { Wrapper, SectionUnderline } from "./CGP.module.css"
import {
  PaddingLeft30,
  PaddingTop20,
  PaddingTop30,
  PaddingTop50,
} from "./../../css/Padding.module.css"
import { classNames } from "./../../helpers/classNames"
import { OrangeTitle } from "../../css/Titre.module.css"

const CGP = () => {
  return (
    <div className={Wrapper}>
      <h1 className={OrangeTitle}>Conditions générales de partenariat</h1>
      <div className={PaddingTop50}>
        <h3>PRÉAMBULE</h3>
        <p className={PaddingTop30}>
          La société WYNIT a pour objet de commercialiser auprès de jeunes âgés
          de 15 ans jusqu’à 26 ans des cartes virtuelles sous forme d’abonnement
          leur permettant de bénéficier d’offres de réduction et d’offres
          promotionnelles auprès de différents établissements partenaires.
        </p>
        <p className={PaddingTop20}>
          Dans ce cadre, la société WYNIT a souhaité mettre en œuvre des
          partenariats avec différents établissements susceptibles d’être
          intéressés par la mise en ligne de réduction et d’offres
          promotionnelles afin de bénéficier d’une visibilité accrue auprès de
          cette clientèle.
        </p>
        <p className={PaddingTop20}>
          Chaque établissement partenaire reconnaît avoir pris connaissance des
          présentes conditions générales de partenariat et déclare en accepter
          les termes.
        </p>
        <p className={PaddingTop20}>
          Ces Conditions Générales de partenariat pourront faire l’objet de
          mises à jour et/ou modifications, les conditions applicables étant
          celles en vigueur lors de la souscription du bon de commande par
          chaque établissement partenaire.
        </p>
      </div>
      <div className={PaddingTop50}>
        <h3>ARTICLE 1 – DEFINITIONS</h3>
        <p className={PaddingTop30}>
          Dans les présentes conditions générales de partenariat, les termes et
          expressions identifiés par une majuscule ont la signification indiquée
          ciaprès, qu’ils soient employés au singulier ou au pluriel.
        </p>
        <p className={PaddingLeft30}>
          - « Etablissement »: désigne tout commerçant, personne physique ou
          personne morale, distribuant des produits et/ou services susceptibles
          d’intéresser une clientèle âgée de 15 à 26 ans.
        </p>
        <p className={PaddingLeft30}>
          - « Utilisateur »: désigne toute personne âgée de plus de 15 ans et de
          moins de 26 ans et poursuivant des études secondaires et/ou
          supérieures éligibles aux offres et promotions mis en ligne par la
          société WYNIT sur son application mobile et son site Internet.
        </p>
        <p className={PaddingLeft30}>
          - « Offre de bienvenue »: désigne l’offre offerte par l’Etablissement
          partenaire lors de la première visite ou à l’occasion de la première
          commande de l’Utilisateur au sein de son Etablissement.
        </p>
        <p className={PaddingLeft30}>
          - « Offre permanente »: désigne toute offre mise en ligne de manière
          permanente pendant toute la durée du partenariat et de ces éventuels
          renouvellements par un Etablissement partenaire à destination des
          Utilisateurs
        </p>
        <p className={PaddingLeft30}>
          - « Offre permanente »: désigne toute offre mise en ligne de manière
          permanente pendant toute la durée du partenariat et de ces éventuels
          renouvellements par un Etablissement partenaire à destination des
          Utilisateurs
        </p>
        <p className={PaddingLeft30}>
          - « Site »: désigne le site internet www.wynit.fr, accessible depuis
          l’adresse www.wynit.fr ou toute autre URL qui pourrait lui être
          substituée.
        </p>
        <p className={PaddingLeft30}>
          - « Application »: désigne l’application mobile « WYNIT »
          téléchargeable via les boutiques d’applications mobiles (telles que
          notamment Google Play Store, Apple AppStore, Android).
        </p>
        <p className={PaddingLeft30}>
          - « WYNIT »: désigne la société WYNIT, SAS immatriculée au registre du
          commerce et des sociétés de Marseille sous le n°900 300 187 et dont le
          siège social est sis 22 Domaine Saint Christophe 13011 MARSEILLE.
        </p>
      </div>
      <div className={PaddingTop50}>
        <h3>ARTICLE 2 – PRÉSENTATION GÉNÉRALE DU PARTENARIAT</h3>
        <p className={classNames([PaddingTop30, SectionUnderline])}>
          2.1 Objet du partenariat
        </p>
        <p className={PaddingTop30}>
          La société WYNIT commercialise par l’intermédiaire de son site
          internet et de son application mobile des abonnements à destination
          des Utilisateurs âgés de plus de 15 ans et de moins de 26 ans leur
          permettant de bénéficier d’offre de bienvenue, d’offre promotionnelle
          et de bons plans auprès de différents Etablissement partenaires sur
          toute la France métropolitaine.
        </p>
        <p className={PaddingTop20}>
          Les Etablissements partenaires bénéficient du fait de ce partenariat
          d’une visibilité accrue auprès d’une clientèle de jeunes (adolescents
          de plus de 15 ans ou jeunes adultes) et ont la possibilité de
          proposer, en sus de l’Offre de bienvenue et de l’Offre permanente
          mises en place lors de la souscription du partenariat, de bon plan
          flash sous forme d’Offres éphémères à durée limitée.
        </p>
        <p className={PaddingTop20}>
          Les Etablissements partenaires bénéficient du fait de ce partenariat
          d’une visibilité accrue auprès d’une clientèle de jeunes (adolescents
          de plus de 15 ans ou jeunes adultes) et ont la possibilité de
          proposer, en sus de l’Offre de bienvenue et de l’Offre permanente
          mises en place lors de la souscription du partenariat, de bon plan
          flash sous forme d’Offres éphémères à durée limitée.
        </p>
        <p className={classNames([PaddingTop30, SectionUnderline])}>
          2.2 Fonctionnement du partenariat
        </p>
        <p className={PaddingTop20}>
          Lors de la souscription du partenariat, chaque Etablissement s’engage
          à mettre en œuvre une Offre de bienvenue ainsi qu’une Offre
          promotionnelle permanente pour l’ensemble des Utilisateurs ayant
          souscrit à l’abonnement via le site internet www.wynit.fr.
        </p>
        <p className={PaddingTop20}>
          Ces offres devront rester en vigueur pendant toute la durée du
          partenariat et ne pourront faire l’objet de modifications pendant
          cette durée.
        </p>
        <p className={PaddingTop20}>
          Chaque Etablissement aura également la possibilité de mettre en ligne
          des Offres éphémères, sous forme d’offres flash, à destination des
          Utilisateurs.
        </p>
        <p className={PaddingTop20}>
          Chaque Etablissement pourra proposer au maximum une Offre éphémère par
          mois.
        </p>
      </div>
      <div className={PaddingTop50}>
        <h3>ARTICLE 3 – DUREE</h3>
        <p className={PaddingTop30}>
          Ce partenariat est conclu pour une durée fixe de 12 mois à compter de
          la signature du bon de commande.
        </p>
        <p className={PaddingTop20}>
          A l’issue de ce délai de douze mois, le contrat de partenariat sera
          renouvelé aux mêmes conditions, sauf dénonciation par l’une ou l’autre
          des parties par courrier recommandé avec avis de réception un mois
          avant le terme du partenariat mentionné dans les conditions
          particulières.
        </p>
        <p className={PaddingTop20}>
          A l’expiration de chaque période annuelle, les parties auront la
          possibilité de renégocier les conditions de ce partenariat.
        </p>
      </div>
      <div className={PaddingTop50}>
        <h3>ARTICLE 4 – FONCTIONNEMENT ET ETENDUE DU PARTENARIAT</h3>
        <p className={classNames([PaddingTop30, SectionUnderline])}>
          4.1 Etendue du partenariat
        </p>
        <p className={PaddingTop20}>
          La société WYNIT commercialise par l’intermédiaire de son site
          internet et de son application mobile des abonnements à destination
          des Utilisateurs âgés de plus de 15 ans et de moins de 26 ans leur
          permettant de bénéficier d’offre de bienvenue, d’offre promotionnelle
          et de bons plans auprès de différents Etablissement partenaires sur
          toute la France métropolitaine.
        </p>
        <p className={PaddingTop20}>
          Pour se faire, la société WYNIT conclut des partenariats auprès de
          différents Etablissements afin que ceux-ci proposent des Offres de
          bienvenue, des Offres permanentes et éventuellement des Offres
          éphémères et bénéficier ainsi d’une visibilité accrue auprès de cette
          clientèle ainsi que d’un nouveau canal de communication.
        </p>
        <p className={PaddingTop20}>
          Le partenariat fonctionne selon les modalités suivantes, que les
          Etablissement acceptent expressément:
        </p>
        <p className={PaddingLeft30}>
          - L’Etablissement met en place une Offre de bienvenue à destination de
          tous les Utilisateurs, ayant souscrit à un abonnement annuel, ainsi
          qu’au bénéfice des autres Utilisateurs susceptibles de remporter cette
          Offre de bienvenue dans le cadre d’une roulette chance accessible
          chaque jour.
        </p>
        <p className={PaddingLeft30}>
          - Cette Offre de bienvenue est valable pour les Utilisateurs ayant
          souscrit un abonnement annuel lors de leur première visite au sein de
          l’Etablissement et pour les autres utilisateurs exclusivement en cas
          de gain.
        </p>
        <p className={PaddingLeft30}>
          - L’Etablissement a la possibilité, en sus, de mettre en œuvre des
          Offres éphémères dans la limite d’une par mois.
        </p>
        <p className={PaddingLeft30}>
          - Ces Offres de bienvenue, permanentes et éphémères sont mises en
          ligne sur l’application mobile par la société WYNIT.
        </p>
        <p className={PaddingLeft30}>
          - Les Utilisateurs ont la possibilité de se géolocaliser via
          l’application afin de visualiser les offres à proximité.
        </p>
        <p className={PaddingLeft30}>
          - Les Utilisateurs peuvent souscrire à l’Offre permanente ou aux
          éventuelles Offres éphémères ainsi qu’aux Offres de bienvenue dans les
          conditions précisées ci-avant.
        </p>
        <p className={PaddingLeft30}>
          - Lors de l’utilisation d’une Offre par un Utilisateur, ce dernier
          reçoit un ticket correspond à l’offre utilisée valide pendant 15
          minutes.
        </p>
        <p className={PaddingLeft30}>
          - En cas de doute, l’Etablissement partenaire a la possibilité de
          solliciter de l’Utilisateur la production d’un document d’identité
          afin de justifier de son identité exacte (notamment de son âge en
          fonction de l’offre).
        </p>
        <p className={PaddingTop20}>
          Ces offres sont matérialisées sous la forme d’annonce récapitulant les
          caractéristiques de l’offre (pourcentage de réduction, cadeaux de
          bienvenue….).
        </p>
        <p className={PaddingTop20}>
          En tant qu’éditeur de la Plateforme « WYNIT », la société WYNIT n’est
          ni le créateur, ni l’organisateur des offres mis en ligne par les
          différents Etablissements, pas plus qu’il ne fournit les produits
          et/ou services des différentes offres.
        </p>
        <p className={PaddingTop20}>
          Les Etablissements sont par conséquent seuls responsables des Offres
          de bienvenue, des Offres permanentes, des Offres éphémères et d’une
          manière générale de l’ensemble des produits et services qu’ils
          proposent. Lorsque des Utilisateurs souscrivent des abonnements et
          utilisent une Offre de bienvenue, une Offre permanente ou une Offre
          éphémère mis en ligne par un Etablissement, ils concluent un contrat
          directement avec l’Etablissement, lequel est seul responsable du bon
          déroulement de sa prestation.
        </p>
        <p className={PaddingTop20}>
          Par ailleurs, la société WYNIT ne contrôle pas et ne garantit pas la
          véracité ou l’exactitude des notations, commentaires ou autre contenu
          des Utilisateurs de l’application WYNIT et susceptible de laisser un
          avis.
        </p>
        <p className={PaddingTop20}>
          Si vous choisissez d’utiliser le site internet et l’application mobile
          WYNIT en tant qu’Etablissement partenaire, votre relation avec la
          société WYNIT est seulement celle d’un entrepreneur tiers /
          Etablissement indépendant, et non d’un employé, mandataire, membre
          d’une co-entreprise ou associé de la société WYNIT pour quelque motif
          que ce soit, et vous agissez exclusivement pour votre compte propre et
          à votre profit, et non pour le compte ou au profit de la société
          WYNIT.
        </p>
        <p className={PaddingTop20}>
          Afin de promouvoir le site internet et l’application mobile WYNIT et
          d’accroître la visibilité des différentes Offres de bienvenue, Offres
          permanentes et Offres éphémères auprès d’Utilisateurs potentiels, les
          différentes Offres peuvent être publiées sur d’autres sites Internet,
          dans des applications et des e-mails, ainsi que dans des publicités en
          ligne et hors ligne.
        </p>
        <p className={PaddingTop20}>
          Le site internet et l’application mobile WYNIT peuvent contenir des
          liens vers des sites Internet ou des ressources tiers. Ces Services
          tiers peuvent être soumis à des Conditions de Service et à des
          pratiques en matière de protection des données à caractère personnel
          différentes. La société WYNIT ne saurait être tenue responsable de la
          disponibilité ou de l’exactitude de ces Services tiers, ou du contenu,
          des produits ou services disponibles auprès de ces Services tiers. Les
          liens vers ces Services tiers ne sauraient être interprétés comme une
          approbation par la société WYNIT de ces Services tiers.
        </p>
        <p className={PaddingTop20}>
          La société WYNIT n'est pas responsable en cas de pannes ou coupures
          d'Internet ou des infrastructures de télécommunications qui ne
          relèvent pas de son contrôle et qui peuvent conduire à des
          interruptions de l'accessibilité de la plateforme WYNIT. La société
          WYNIT peut améliorer ou modifier la Plate-forme WYNIT et ajouter de
          nouveaux Services à tout moment.
        </p>
        <p className={classNames([PaddingTop30, SectionUnderline])}>
          4.2 Mise en ligne des offres
        </p>
        <p className={PaddingTop20}>
          Lors de la mise en place du partenariat, l’Etablissement communiquera
          à la société WYNIT les conditions de son Offre de bienvenue ainsi que
          celle de son Offre permanente.
        </p>
        <p className={PaddingTop20}>
          Ces offres seront mises en ligne sur l’application mobile WYNIT.
        </p>
        <p className={PaddingTop20}>
          Chaque mois, l’Etablissement pourra proposer une Offre éphémère d’une
          durée déterminée. Dans ce cas, l’Etablissement transmettra à la
          société WYNIT les conditions de cette Offre éphémère pour mise en
          ligne.
        </p>
        <p className={PaddingTop20}>
          Cette mise en ligne s’effectuera dans un délai de 72 heures.
        </p>
        <p className={PaddingTop20}>
          Les offres seront publiées sur l’application mobile WYNIT et seront
          visibles par les Utilisateurs et/ou Visiteurs même non encore inscrit
          sur le site.
        </p>
        <p className={PaddingTop20}>
          En créant une Offre, l’Etablissement accepte d’en respecter les
          termes.
        </p>
        <p className={PaddingTop20}>
          La société WYNIT se réserve le droit, à sa seule discrétion et sans
          préavis, de retirer du site et de l’application une Offre qui ne
          serait pas conforme aux présentes conditions générales ou qui serait
          susceptible de porter atteinte à l’image du site et de l’application «
          WYNIT ».
        </p>
      </div>
      <div className={PaddingTop50}>
        <h3>ARTICLE 5 – OBLIGATIONS DES ETABLISSEMENTS</h3>
        <p className={PaddingTop20}>
          L’Etablissement s’engage à respecter les présentes conditions
          générales de partenariat ainsi que la politique de confidentialité.
        </p>
        <p className={PaddingTop20}>
          L’Etablissement s’engage également à respecter la législation en
          vigueur dans le cadre des Offres proposées à destination des
          Utilisateurs, qu’il s’agisse des Offres de bienvenue, des Offres
          permanentes ou des Offres éphémères.
        </p>
        <p className={PaddingTop20}>
          Ainsi, en particulier, les Etablissements s’interdisent de vendre de
          l’alcool aux personnes mineures, ni de proposer des offres incluant la
          vente de boissons alcoolisées.
        </p>
        <p className={PaddingTop20}>
          Par ailleurs, l’Etablissement s’engage à respecter les termes des
          offres mises en ligne sur le site et l’application WYNIT.
        </p>
        <p className={PaddingTop20}>
          L’Etablissement s’engage également à fournir le meilleur accueil pour
          la réalisation des offres à destination des Utilisateurs.
        </p>
        <p className={PaddingTop20}>
          En cas de doute sur l’identité de l’Utilisateur, l’Etablissement
          partenaire s’engage à solliciter la production d’une pièce d’indetité
          afin de s’assurer de l’identité de l’Utilisateur de l’Offre.
        </p>
        <p className={PaddingTop20}>
          En tout état de cause, l’Etablissement garantit la société WYNIT de
          toute réclamation à ce titre.
        </p>
        <p className={PaddingTop20}>
          De manière générale, et sans que cette liste puisse être considérée
          comme exhaustive, l’Etablissement s’interdit:
        </p>
        <p className={PaddingLeft30}>
          - De fournir des informations incomplètes, erronées ou inexactes, ou
          dont il n’est pas titulaire ou détenteur légitime;
        </p>
        <p className={PaddingLeft30}>
          - D’utiliser le Site ou l’Application dans des conditions ou selon des
          modalités qui entraîneraient la violation par lui d’autres
          dispositions ou engagements contractuels ou constitutives d’une
          fraude;
        </p>
        <p className={PaddingLeft30}>
          - De télécharger, reproduire, enregistrer, copier, compiler,
          décompiler, partager avec un tiers ou exploiter, par tout procédé ou
          moyen, le contenu du Site et de l’application et les résultats de son
          utilisation;
        </p>
        <p className={PaddingLeft30}>
          - D’entraver ou de perturber le fonctionnement du Site et/ou de
          l’application mobile;
        </p>
        <p className={PaddingLeft30}>
          - De déposer, diffuser ou utiliser sur ou depuis le Site des contenus
          portant atteinte aux droits de tiers, et en particulier à des droits
          de propriété intellectuelle (marque, droit d’auteur, modèles en
          particulier), aux droits de personne (notamment usurpation d’identité,
          diffamation, insultes, injures, dénigrement, harcèlement, etc.), au
          respect de la vie privée (en ce compris le droit à l’image), à l’ordre
          public et aux bonnes mœurs (notamment apologie des crimes contre
          l’humanité, incitation à la haine raciale, atteinte à la dignité
          humaine, etc.) et, plus généralement, à la réglementation applicable
          en vigueur
        </p>
        <p className={PaddingTop20}>
          L’Etablissement garantit la société WYNIT contre toute réclamation,
          action et/ou revendication d’autres Utilisateurs ou de tiers
          concernant les Offres mises en ligne via le Site et l’Application.
        </p>
        <p className={PaddingTop20}>
          Lors de la souscription du partenariat, l’Etablissement communiquera à
          la société WYNIT l’ensemble des données nécessaires à la fourniture de
          l’offre et notamment :
        </p>
        <p className={PaddingLeft30}>
          - L’identité complète de l’établissement (nom et/ou dénomination
          sociale).
        </p>
        <p className={PaddingLeft30}>- Adresse de l’établissement.</p>
        <p className={PaddingLeft30}>
          - Enseigne, marque ou logo de l’Etablissement.
        </p>
        <p className={PaddingTop20}>
          L’Etablissement garantit que les données qu'il communique sont
          exactes, complètes et conformes à la réalité. La responsabilité de
          l’Etablissement est susceptible d’être engagée tant vis-à-vis de la
          société WYNIT que des Etudiants en cas de fournitures de données
          erronées. Par ailleurs, l’Etablissement accepte expressément
          l’exploitation de son enseigne, logo, marque et autres droits de
          propriétés intellectuelles à des fins de promotions du site internet,
          de l’application mobile et des offres mises en ligne, et ce y compris
          sur des sites tiers ainsi que sur les réseaux sociaux.
        </p>
        <p style={{ color: "red" }} className={PaddingTop20}>
          L’Etablissement s’engage par ailleurs à informer sans délai la société
          WYNIT de tout changement concernant les données communiquées lors de
          la création de son compte partenaire via l’adresse de messagerie
          suivante :
        </p>
        <p className={PaddingTop20}>
          Dans le cas où les informations transmises par l’Etablissement se
          révéleraient fausses, incomplètes, obsolètes ou ne permettant pas la
          fourniture des produits et Service et la réalisation des Offres dans
          les meilleures conditions, la société WYNIT se réserve le droit de
          suspendre les Offres mis en ligne par l’Etablissement sur le site et
          l’application mobile WYNIT, sans préavis et sans que cela ne lui ouvre
          un quelconque droit à indemnité.
        </p>
        <p className={PaddingTop20}>
          Enfin, chaque Etablissement s’engage à assurer l’effectivité des
          offres mises en ligne. A défaut, la société WYNIT aura la possibilité
          de résilier le présent partenariat et de retirer les Offres mises en
          ligne.
        </p>
      </div>
      <div className={PaddingTop50}>
        <h3>ARTICLE 6 – EXCLUSIVITE</h3>
        <p className={PaddingTop30}>
          L’accès à ce partenariat est gratuit pour l’Etablissement
        </p>
        <p className={PaddingTop20}>
          Par le biais de ce partenariat, l’Etablissement bénéficie d’une
          visibilité accrue auprès d’une clientèle jeune de plus de 15 ans et de
          moins de 26 ans. L’Etablissement bénéficie également de publicité via
          le site Internet, l’application WYNIT et les différents réseaux
          sociaux sur lesquels la société WYNIT est présente.
        </p>
        <p className={PaddingTop20}>
          En contrepartie, l’Etablissement consent une exclusivité au bénéfice
          de la société WYNIT. Ainsi, l’Etablissement s’engagent à ne pas
          souscrire un partenariat similaire avec une autre société pendant
          toute la durée du partenariat conclu avec la société WYNIT.
        </p>
      </div>
      <div className={PaddingTop50}>
        <h3>ARTICLE 7 – EVALUATION DES ETABLISSEMENTS</h3>
        <p className={PaddingTop30}>
          Les Etablissements sont informés que la société WYNIT met à la
          disposition des Utilisateurs un système d’évaluation des
          Etablissements par rapport aux Offres réalisées. L’Etablissement
          accepte expressément que les offres mises en ligne et les prestations
          subséquentes puissent faire l’objet d’avis par les Utilisateurs en
          ayant bénéficié. Ces évaluations ne seront pas rendues publiques sur
          le site et l’application « wynit » mais seront réservées à un usage
          internet à la société WYNIT.
        </p>
        <p className={PaddingTop20}>
          Ce système d’évaluation a, notamment, pour objet de mieux orienter les
          Utilisateurs en fonction de leur attente et de leur recherche d’Offre.
          Cette évaluation reflète l’opinion des Utilisateurs et non celle de la
          société WYNIT. Ces évaluations pourront être vérifiées par la société
          WYNIT.
        </p>
        <p className={PaddingTop20}>
          La société WYNIT ne pourra pas être tenue pour responsable en cas
          d’avis infondés et/ou mensongers. Pour éviter la mise en ligne
          d’évaluation sans lien avec un service fourni, il ne sera possible de
          laisser une évaluation d’un Etablissement que pour les Utilisateurs
          ayant bénéficié et utilisé une Offre. Les évaluations laissées par les
          Utilisateurs ne seront pas visibles sur le Site et l’Application «
          wynit » et seront réservées à un usage interne à la société WYNIT.
        </p>
        <p className={PaddingTop20}>
          Les Utilisateurs seront amenés à évaluer les Etablissements sur la
          base des critères suivants, lesquels ne sont pas exhaustifs :
        </p>
        <p className={PaddingLeft30}>- Qualité de l’accueil</p>
        <p className={PaddingLeft30}>- Qualité de la prestation</p>
        <p className={PaddingTop20}>
          La société WYNIT se réserve le droit de suspendre et de résilier le
          partenariat des Etablissements ayant reçu plus de cinq avis négatifs
          vérifiés.
        </p>
        <p className={PaddingTop20}>
          Dans le cadre de ce partenariat, la société WYNIT effectuera avec les
          Etablissements partenaires une réunion d’information annuelle afin de
          faire le bilan des évaluation reçues par l’Etablissement partenaire en
          mettant en avant les points forts et les points faibles des Offres et
          ainsi améliorer la qualité des Offres et des prestations offertes par
          l’Etablissement.
        </p>
      </div>
      <div className={PaddingTop50}>
        <h3>ARTICLE 8 –RESILIATION – SUSPENSION</h3>
        <p className={classNames([PaddingTop30, SectionUnderline])}>
          8.1 Avertissement - Suspension du partenariat - Résiliation pour faute
        </p>
        <p className={PaddingTop30}>
          Afin de préserver l’image du Site et de l’application mobile et sans
          préjudice des dommages et intérêts éventuels qu’elle serait en droit
          de réclamer, la société WYNIT se réserve le droit de donner un
          avertissement à un Etablissement, de suspendre temporairement le
          partenariat et et/ou de résilier la présente convention de
          partenariat, de plein droit, avec effet immédiat, dans les cas
          suivants:
        </p>
        <p className={PaddingLeft30}>
          - Non-respect des présentes conditions générales
        </p>
        <p className={PaddingLeft30}>
          - Fourniture de données fausses, erronées ou inexactes
        </p>
        <p className={PaddingLeft30}>
          - Non-respect des lois et de la réglementation applicable
        </p>
        <p className={PaddingLeft30}>
          - Diffusion de commentaires et/ou d’images diffamatoires ou injurieux
          ou contraire aux lois et aux règlements
        </p>
        <p className={PaddingLeft30}>
          - Non-respect et non accomplissement des offres mises en ligne sur le
          site
        </p>
        <p className={PaddingTop20}>
          Si la société WYNIT est avisée d’un tel manquement imputable à un
          Etablissement, elle lui adressera immédiatement un courriel l’invitant
          à remédier sans délai au manquement constaté. En fonction de la
          gravité du manquement imputable à l’Etablissement, la société WYNIT
          pourra décider de suspendre temporairement et/ou définitivement le
          partenariat.
        </p>
        <p className={PaddingTop20}>
          A défaut pour l’Etablissement d’avoir remédié au manquement signalé
          dans un délai de 30 jours suivant la réception de la demande formulée
          par la société WYNIT, cette dernière pourra résilier de plein droit et
          sans préavis le partenariat avec l’Etablissement, sans que ce dernier
          ne puisse prétendre à aucune indemnité du fait de cette résiliation.
        </p>
        <p className={PaddingTop20}>
          En fonction de la gravité du manquement signalé ou en cas de
          manquements répétés de l’Etablissement à ses obligations, la société
          WYNIT pourra résilier de plein droit et sans préavis le partenariat
          avec l’Etablissement, sans que ce dernier ne puisse prétendre à aucune
          indemnité du fait de cette résiliation.
        </p>
        <p className={classNames([PaddingTop30, SectionUnderline])}>
          8.2 Conséquences de la résiliation du partenariat
        </p>
        <p className={PaddingTop30}>
          En cas de résiliation du partenariat, pour quelque motif que ce soit,
          les Offres mises en ligne par l’Etablissement seront immédiatement
          retirées et le profil de l’Etablissement sera immédiatement retiré du
          site et de l’application WYNIT.
        </p>
      </div>
      <div className={PaddingTop50}>
        <h3>ARTICLE 9 – RESPONSABILITE</h3>
        <p className={PaddingTop30}>
          Chaque Etablissement est responsable des informations qu’il fournit et
          des offres qu’il met en ligne par l’intermédiaire du site et de
          l’application WYNIT.
        </p>
        <p className={PaddingTop20}>
          Dans le cadre de la réalisation des prestations incluses dans les
          différentes Offres mises en ligne, chaque Etablissement agit sous sa
          seule et entière responsabilité, notamment conformément aux
          dispositions du code civil relatives au droit des obligations et à la
          responsabilité civile contractuelle.
        </p>
        <p className={PaddingTop20}>
          Chaque Etablissement est par conséquent seul responsable des
          prestations fournies dans le cadre des offres mises en ligne à
          destination des Utilisateurs.
        </p>
        <p className={PaddingTop20}>
          La société WYNIT s'engage à faire ses meilleurs efforts pour sécuriser
          son site. Néanmoins, aucun site n'étant inviolable, sa responsabilité
          ne pourra être mise en cause si des données indésirables sont
          importées et installées sur son site à son insu, ou si à l'inverse,
          les données transmises sont détournées pour être utilisées
          illicitement par des tiers.
        </p>
      </div>
      <div className={PaddingTop50}>
        <h3>ARTICLE 10 – DONNEES PERSONNELLES</h3>
        <p className={PaddingTop30}>
          La mise en ligne d’offres sur le site et l’application WYNIT implique
          la collecte et le traitement de données à caractère personnel
          concernant les Etablissement. Chaque Etablissement reconnaît avoir
          préalablement à la souscription du partenariat pris connaissance de la
          politique de confidentialité.
        </p>
      </div>
      <div className={PaddingTop50}>
        <h3>ARTICLE 11 – PROPRIETE INTELLECTUELLE</h3>
        <p className={classNames([PaddingTop30, SectionUnderline])}>
          11.1 Droits de propriété intellectuelle du site et de l’application
          WYNIT
        </p>
        <p className={PaddingTop30}>
          Le Site et l’Application WYNIT constitue une œuvre protégée par la
          législation en vigueur, et notamment les articles L. 122-1 et suivants
          du Code de la propriété intellectuelle. Les textes, photographies,
          images animées ou non, savoir-faire, dessins, graphismes et tout autre
          élément composant le Site et l’Application sont la propriété de la
          société WYNIT ou de tiers ayant autorisé la société WYNIT à les
          utiliser.
        </p>
        <p className={PaddingTop20}>
          À ce titre et conformément aux dispositions du Code de la propriété
          intellectuelle, toute reproduction, représentation, modification ou
          adaptation totale ou partielle du Site et de l’Application et/ou de
          tout ou partie des éléments se trouvant sur le Site et l’Application
          ou qui y sont incorporés est strictement interdite et constitutive du
          délit de contrefaçon pouvant engager la responsabilité civile et
          pénale de son auteur.
        </p>
        <p className={PaddingTop20}>
          L’utilisation du Site ne confère en aucune façon un droit de propriété
          ou un droit de propriété intellectuelle sur le Site et l’Application
          et/ou les éléments se trouvant sur le Site et l’Application à
          l’exception d’un droit personnel d’accès, non exclusif, limité
          exclusivement à l’utilisation du Site et de l’Application et des
          éléments se trouvant sur le Site et de l’Application conformément aux
          finalités définies dans le cadre des présentes conditions générales.
        </p>
        <p className={PaddingTop20}>
          Les marques et logos WYNIT, ainsi que les marques et logos des
          partenaires de WYNIT sont des marques déposées. Toute reproduction
          totale ou partielle de ces marques et/ou logos sans l'autorisation
          préalable et écrite de la société WYNIT est interdite.
        </p>
        <p className={PaddingTop20}>
          La société WYNIT est producteur des bases de données. En conséquence,
          toute extraction et/ou réutilisation de la ou des bases de données au
          sens des articles L 342-1 et L 342-2 du code de la propriété
          intellectuelle est interdite.
        </p>
        <p className={PaddingTop20}>
          La société WYNIT se réserve la possibilité de saisir toutes voies de
          droit à l'encontre des personnes qui n'auraient pas respecté les
          interdictions contenues dans le présent article.
        </p>
        <p className={classNames([PaddingTop30, SectionUnderline])}>
          11.2 Droits de propriété intellectuelle des Etablissements
        </p>
        <p className={PaddingTop20}>
          Afin de permettre la mise en ligne des offres de bienvenue, des Offres
          permanentes et des Offres éphémères, chaque Etablissement concède à la
          société WYNIT, à titre gratuit, un droit d’exploitation non exclusif
          et transférable sur l’image de son Etablissement ainsi qu’un droit
          d’exploitation non exclusif, transférable, sous licenciable, à titre
          gracieux, pour le monde entier sur l’ensemble du contenu des Offres de
          bienvenue, Offres permanentes et Offres éphémères et notamment sur les
          photographies, textes, vidéos, illustrations, marques, logos, titres,
          au fur et à mesure de leur publication sur le Site ou l’Application,
          ainsi qu’une licence sur l’ensemble des droits de propriété
          intellectuelle afférant au contenu des différentes offres, et ce pour
          toute la durée du partenariat, incluant les éventuels renouvellements.
        </p>
        <p className={PaddingTop20}>
          Les droits ainsi concédés incluent le droit de reproduire,
          représenter, diffuser, adapter, modifier, réaliser une œuvre dérivée,
          traduire tout ou partie du contenu des Offres de bienvenue, Offres
          permanentes et Offres éphémères par tous procédés, sous quelque forme
          que ce soit et sur tous supports (numérique, imprimé…) connus ou
          inconnus à ce jour, dans le cadre du service WYNIT, et ce à la société
          WYNIT est présente et notamment les pages Facebook, Instagram et
          Twitter de WYNIT.
        </p>
        <p className={PaddingTop20}>
          En particulier, les photographies des Offres de bienvenue, Offres
          permanentes et Offres éphémères pourront être reproduites et intégrées
          dans des formats publicitaires diffusés sur le Site Internet et/ou sur
          les réseaux sociaux. Chaque Etablissement donne son consentement à la
          reprise de ses différentes offres et du contenu de ces dernières ainsi
          que du profil de l’Etablissement sur les réseaux sociaux, notamment
          Facebook, Instagram et Twitter. Par conséquent, l’Etablissement
          atteste avoir pris connaissance des conditions générales d’utilisation
          des sites Facebook, Instagram, et Twitter et en accepter les termes,
          particulièrement en matière de réutilisation du contenu et des données
          personnelles.
        </p>
      </div>
      <div className={PaddingTop50}>
        <h3>ARTICLE 12 – DISPOSITIONS GENERALES </h3>
        <p className={classNames([PaddingTop30, SectionUnderline])}>
          12.1 Non-validité partielle
        </p>
        <p className={PaddingTop30}>
          Si l'une quelconque des dispositions des présentes conditions
          générales de partenariat venait à être déclarée nulle ou sans objet au
          regard d'une disposition législative ou réglementaire en vigueur et/ou
          d’une décision de justice ayant autorité de la chose jugée, elle sera
          réputée non écrite et n'entraînera pas la nullité des autres
          dispositions
        </p>
        <p className={classNames([PaddingTop30, SectionUnderline])}>
          12.2 Tolérances
        </p>
        <p className={PaddingTop30}>
          Le fait pour l'une des Parties de ne pas se prévaloir d'un manquement
          par l'autre partie à l'une quelconque des obligations visées dans les
          présentes Conditions générales de partenariat ne saurait être
          interprété pour l'avenir comme une renonciation à l'obligation en
          cause.
        </p>
        <p className={classNames([PaddingTop30, SectionUnderline])}>
          12.3 Loi applicable - Litige
        </p>
        <p className={PaddingTop20}>
          Les présentes conditions générales de partenariat sont exécutées et
          interprétées en application du droit français.
        </p>
        <p className={PaddingTop20}>
          En cas de litige, l’Etablissement s'adressera par priorité à la
          société WYNIT en vue d'un règlement amiable du différend. A défaut,
          les tribunaux de commerce de Marseille seront seuls compétents pour
          trancher le litige.
        </p>
      </div>
    </div>
  )
}

export default CGP
