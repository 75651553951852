import firebase from "gatsby-plugin-firebase"
const isBrowser = typeof window !== "undefined"

export const getUserDocument = (id, setUser, isRedirection) => {
  firebase
    .firestore()
    .collection("users")
    .doc(id)
    .get()
    .then(doc => {
      setUser({
        id: doc.id,
        firstName:
          doc._delegate._document.data.value.mapValue.fields.firstName
            .stringValue,
        lastName:
          doc._delegate._document.data.value.mapValue.fields.lastName
            .stringValue,
        gender:
          doc._delegate._document.data.value.mapValue.fields.gender.stringValue,
        birthDate:
          doc._delegate._document.data.value.mapValue.fields.birthDate
            .integerValue,
        refImage:
          doc._delegate._document.data.value.mapValue.fields.refImage
            .stringValue,
        city:
          doc._delegate._document.data.value.mapValue.fields.city.stringValue,
        postalCode:
          doc._delegate._document.data.value.mapValue.fields.postalCode
            .stringValue,
        phoneNumber:
          doc._delegate._document.data.value.mapValue.fields.phoneNumber
            .stringValue,
        subscription: {
          type:
            doc._delegate._document.data.value.mapValue.fields.subscription
              .mapValue.fields.type.stringValue,
          start:
            doc._delegate._document.data.value.mapValue.fields.subscription
              .mapValue.fields.start.integerValue,
          end:
            doc._delegate._document.data.value.mapValue.fields.subscription
              .mapValue.fields.end.integerValue,
        },
        usedOffers:
          doc._delegate._document.data.value.mapValue.fields.usedOffers
            .arrayValue.values,
        email:
          doc._delegate._document.data.value.mapValue.fields.email.stringValue,
        password:
          doc._delegate._document.data.value.mapValue.fields.password
            .stringValue,
        gifts:
          doc._delegate._document.data.value.mapValue.fields.gifts.arrayValue
            .values,
      })
      if (isBrowser && isRedirection) {
        if (
          doc._delegate._document.data.value.mapValue.fields.subscription
            .mapValue.fields.type.stringValue === "admin"
        ) {
          window.location.href = "/admin/profil"
        } else {
          window.location.href = "/utilisateurs/profil"
        }
      }
    })
    .catch(error => {
      //console.log("error getting user document: ", error)
      setUser(null)
    })
}

export const getUser = setUser => {
  firebase.auth().onAuthStateChanged(firebaseUser => {
    if (firebaseUser) {
      getUserDocument(firebaseUser.uid, setUser, false)
      return firebaseUser.uid
    } else {
      setUser(null)
      return null
    }
  })
}

export const signInWithEmailAndPassword = (email, password, setUser) => {
  firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(result => {
      getUserDocument(result.user.uid, setUser, true)
      return result.uid
    })
    .catch(err => {
      //console.log("error during sign in: ", err)
      setUser(null)
      return null
    })
}

export const logOut = () => {
  if (isBrowser) {
    window.location.href = "/"
  }
  firebase
    .auth()
    .signOut()
    .then(() => {})
    .catch(err => {
      //console.log("error during logOut: ", err)
    })
}
