import React, { useState, useEffect } from "react"
import Layout from "./../components/Layout"
import Cgp from "./../nav/CGP"
import { Helmet } from "react-helmet"
import { getUser } from "./../auth"

const CGPPage = () => {
  const [user, setUser] = useState(undefined)
  useEffect(() => {
    getUser(setUser)
  }, [])

  return (
    <Layout user={user}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>WYNIT | Conditions générales de partenariat</title>
        <meta
          name="description"
          content="WYNIT, les bons plans marseillais pour les 15-25 ans. Sur cette page,
          tu trouveras les conditions générales de partenariat de WYNIT."
        />
        <meta
          name="keywords"
          content="bons plans,marseille,réductions,jeunes,conditions générales de partenariat"
        />
        <link
          rel="canonical"
          href="https://wynit.com/conditions-generales-de-partenariat"
        />
      </Helmet>
      <Cgp />
    </Layout>
  )
}

export default CGPPage
